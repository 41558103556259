import React from "react";

import './styles.css'

function Introduction() {
    return (
        <div className="Introduction">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-9 col-lg-8 col-xl-9">
                        <div>
                            <div className="Introduction__Title">Research summarised for your leisure</div>
                            <div className="Introduction__Subtitle">Research publications summarised into bite-sized audio and text called Publetts</div>
                            <div className="Introduction__Buttons">
                                <a className="Introduction__Appstorelink" href="https://apple.co/3A8Hu1e" rel="noreferrer" target="_blank">
                                    <img alt="Apple store badge." className="Introduction__Appstoreicon" src={process.env.PUBLIC_URL + '/assets/app-store.svg'} />
                                </a>
                                <a className="Introduction__Appstorelink" href="https://play.google.com/store/apps/details?id=com.publett" rel="noreferrer" target="_blank">
                                    <img alt="Google Play store badge" className="Introduction__Appstoreicon" src={process.env.PUBLIC_URL + '/assets/play-store.svg'} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introduction;