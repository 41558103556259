import React from "react";
import { Helmet } from "react-helmet-async";
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, EmailShareButton, EmailIcon } from 'react-share';
import './styles.css';

import { Link, useParams } from "react-router-dom";
import BlogData from '../../data/blogs';

import ReactMarkdown from 'react-markdown'


function BlogPostPage() {
    // const [documentWidth, setDocumentWidth] = React.useState(document.body.clientWidth);
    // const breakpoint = 992;

    let { id } = useParams();

    const shareUrl = 'https://www.publett.com/blog/' + id;

    let author, authorBio, authorImage, authorRole, content, date, duration, image, title = 'none';
    image = 'iphone2.jpeg';

    let blogPost = BlogData.find((i) => i.id === id);
    if (blogPost) {
        author = blogPost.author;
        authorBio = blogPost.authorBio;
        authorImage = blogPost.authorImage;
        authorRole = blogPost.authorRole;
        content = blogPost.content;
        date = blogPost.date;
        duration = blogPost.duration;
        image = blogPost.image;
        title = blogPost.title;
    }
    // let location = process.env.PUBLIC_URL + 'assets/' + image;
    let headerImageURL = `url('../../assets/blogs/${image}')`;

    const MoreBlogPosts = () => {
        // copy original dataset
        let tempData = JSON.parse(JSON.stringify(BlogData));
        
        //find index of current post
        let pos = tempData.findIndex((i) => i.id === id);
        
        //remove current post from dataset
        tempData.splice(pos, 1);
        
        return (
            tempData.slice(0, 4).map((item) => (
                <div key={item.id} className="col">
                    <Link style={{ textDecoration: 'none', color: '#333333' }} to={`/blog/${item.id}`}>
                        <div className="row blogpost__share__body g-2">
                            <div className="col-2 col-md-3 col-lg-2 col-xl-3 ">
                                <div className="ratio ratio-1x1 blogpost__thumbnail" style={{ '--me': `url('../../assets/blogs/${item.image}'` }} />
                            </div>
                            <div className="col-10 col-md-9 col-lg-10 col-xl-9 Blogpost__More__Post__Title">{item.title}</div>
                        </div>
                    </Link>
                </div>
            )
            )
        )
    }

    return (
        <>
            <Helmet>
                <title>Publett | Blog | {title}</title>
                <meta name="description" content="Publett - Science made a leisure" />

                {/* facebook meta tags */}
                <meta property="og:title" content={title} />
                <meta property="og:type" content="article" />
                <meta property="og:image" content={`https://www.publett.com/assets/blogs/${image}`} />
                <meta property="og:image:url" content={`https://www.publett.com/assets/blogs/${image}`} />
                <meta property="og:image:secure_url" content={`https://www.publett.com/assets/blogs/${image}`} />
                <meta property="og:description" content="Read at publett.com" />
                <meta property="og:url" content={shareUrl} />
                <meta property="og:site_name" content="Publett" />
                <meta property="og:image:alt" content={title} />

                {/* twitter meta tags */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:text:title" content={title} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content="Read at publett.com" />
                <meta name="twitter:site" content="Publett" />
                <meta name="twitter:image" content={`https://www.publett.com/assets/blogs/${image}`} />
                <meta name="twitter:site" content="@publett" />
                <meta name="twitter:creator" content="@publett" />
            </Helmet>
            <div className="blog__introduction blogpost__banner" style={{ "--me": headerImageURL }}>
            </div>

            <div className="container mt-5">
                <div className="row m-3">
                    <div className="cl-lg-6 col-xl-8">
                        {blogPost ? (
                            <>
                                <h1 style={{ marginBottom: 30, marginTop: 30 }}>{title}</h1>
                                <h4 style={{ fontSize: 16 }}>{author} <span style={{ fontWeight: 200 }}> {authorRole}</span></h4>

                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <p style={{ fontSize: 12, color: '#666666' }}>{date} | {duration} minute read</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={{ fontSize: 12, color: '#666666', textAlign: 'right' }}>
                                            <FacebookShareButton className="Blog__Post__Share__Icon" url={shareUrl} quote="something">
                                                <span className="share-icon">
                                                    <FacebookIcon bgStyle={{ fill: '#f37c7c' }} size={25} round />
                                                </span>
                                            </FacebookShareButton>
                                            <LinkedinShareButton className="Blog__Post__Share__Icon" title={title} summary={title} source="https://www.publett.com" url={shareUrl}>
                                                <span className="share-icon">
                                                    <LinkedinIcon bgStyle={{ fill: '#f37c7c' }} size={25} round />
                                                </span>
                                            </LinkedinShareButton>
                                            <TwitterShareButton className="Blog__Post__Share__Icon" title={title} url={shareUrl}>
                                                <span className="share-icon">
                                                    <TwitterIcon bgStyle={{ fill: '#f37c7c' }} size={25} round />
                                                </span>
                                            </TwitterShareButton>
                                            <WhatsappShareButton className="Blog__Post__Share__Icon" url={shareUrl} title={title}>
                                                <span className="share-icon">
                                                    <WhatsappIcon bgStyle={{ fill: '#f37c7c' }} size={25} round />
                                                </span>
                                            </WhatsappShareButton>
                                            <EmailShareButton className="Blog__Post__Share__Icon" url={shareUrl} subject={title}>
                                                <span className="share-icon">
                                                    <EmailIcon bgStyle={{ fill: '#f37c7c' }} size={25} round />
                                                </span>
                                            </EmailShareButton>
                                        </p>
                                    </div>
                                </div>

                                <hr style={{ marginTop: 0, borderColor: '#333333', borderStyle: 'dotted', marginBottom: 10 }} />
                                <ReactMarkdown className="blog__details">{content}</ReactMarkdown>
                                <hr />
                                <div className="row mt-5 g-3 mb-5">
                                    <div className="col-12"></div>
                                    <div className="col-2">
                                        <img alt={author} src={`../assets/${authorImage}`} className="w-100" style={{ borderRadius: '50%' }} />
                                    </div>
                                    <div className="col-10">
                                        <p className="blogpost__author__pill">Author</p>
                                        <h4 style={{ margin: 0, padding: 0, marginBottom: 10, marginTop: 10, fontSize: 18 }}>{author}</h4>
                                        <p>{authorBio}</p></div>
                                </div>
                            </>
                        ) : (
                            <>
                                <h1 style={{ marginBottom: 30, marginTop: 30 }}>Sorry, we couldn't find that blog!</h1>
                                <p>Why not read our latest blog post?</p>
                                <div style={{ padding: 0, backgroundColor: '#ffffff', borderRadius: 10 }}>
                                    {
                                        BlogData.slice(0, 1).map((item) => (
                                            <Link key={item.id} style={{ textDecoration: 'none', color: '#333333' }} to={`/blog/${item.id}`}>
                                                <div style={{ position: 'relative', borderRadius: 5 }}>
                                                    <img alt={item.title} src={`../../assets/blogs/${item.image}`} width="100%" style={{ objectFit: 'cover', borderRadius: '5px 5px 0px 0px' }} />
                                                    <div style={{ borderRadius: '0px 0px 5px 5px', backgroundColor: '#ffffff', bottom: 0, padding: 20 }}>
                                                        <div style={{ fontSize: 18 }}>{item.title}</div>
                                                        <div style={{ fontSize: 12 }}>{item.duration} min | read now</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))
                                    }
                                </div>
                            </>
                        )}
                    </div>

                    <div className="col-lg-12 col-xl-3 offset-0 offset-xl-1 p-0">

                        <div className="row g-3" style={{ position: 'sticky', top: 80, marginBottom: 10, marginTop: 20 }}>
                            <div className="col-xl-12 blogpost__share p-0 shadow-sm">
                                <div style={{ padding: 20, paddingBottom: 0 }}>
                                    <h4>More blog posts</h4>
                                </div>

                                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-1 g-3 Blogpost__More__Posts">
                                    <MoreBlogPosts />
                                </div>

                            </div>
                            {/* publett and pubcast*/}
                            <div className="col-12 p-0">
                                <div className="row row-cols-2 g-3">
                                    {/* publett */}
                                    <div className="col-12 col-md-6 col-xl-12">
                                        <div className="Blogpost__Publett p-3 h-100 shadow-sm">
                                            <h4>Grow, learn and study on the go</h4>
                                            <p>Our Apple and Android mobile app allows you to grow your knowledge, confidence and research topics.</p>
                                            <div>
                                                <a rel="noreferrer" href="https://apple.co/3A8Hu1e" target="_blank"><img alt="Apple appstore icon." src={process.env.PUBLIC_URL + '/assets/app-store.svg'} style={{ height: 30, marginRight: 15 }} /></a>
                                                <a rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.publett" target="_blank"><img alt="Google playstore icon." src={process.env.PUBLIC_URL + '/assets/play-store.svg'} style={{ height: 30 }} /></a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* pubcast */}
                                    <div className="col-12 col-md-6 col-xl-12 sha">
                                        <div className="blogpost__pubcast p-3 h-100 shadow-sm">
                                            <h4>Fancy some controversy?</h4>
                                            <p>Our pubcast Unearth's controversies, discoveries and addresses the uncomfortable topics in academia.</p>
                                            <div>
                                                <p className="pubcast__body">Available on</p>
                                                <a rel="noreferrer" href="https://open.spotify.com/show/5LxCfscMprj4VFgd2qNmi4?si=76643cd7caa94223" target="_blank"><img alt="Spotify icon." src={process.env.PUBLIC_URL + '/assets/podcast_spotify.webp'} style={{ width: 30, marginRight: 15 }}></img></a>
                                                <a rel="noreferrer" href="https://podcasts.apple.com/us/podcast/the-diary-of-a-researcher-by-publett/id1653300115" target="_blank" title="Listen on Apple Podcasts"><img alt="Apple podcast icon." src={process.env.PUBLIC_URL + '/assets/podcast_apple.webp'} style={{ width: 30, marginRight: 15 }}></img></a>
                                                <a rel="noreferrer" href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnBvZGJlYW4uY29tL3RoZS1kaWFyeS1vZi1hLXJlc2VhcmNoZXIvZmVlZC54bWw" target="_blank"><img alt="Google podcast icon." src={process.env.PUBLIC_URL + '/assets/podcast_google.webp'} style={{ width: 30, marginRight: 15 }}></img></a>
                                                <a rel="noreferrer" href="https://player.fm/series/3414058" target="_blank"><img alt="Player.fm icon." src={process.env.PUBLIC_URL + '/assets/podcast_player-fm.webp'} style={{ width: 30, marginRight: 15 }}></img></a>
                                                <a rel="noreferrer" href="https://music.amazon.com/podcasts/79513bcb-3e3e-41c6-9c87-3fa9a77ee403" target="_blank"><img alt="Amazon music icon." className="no-selection" src={process.env.PUBLIC_URL + '/assets/podcast_amazon.webp'} style={{ width: 30, marginRight: 15 }}></img></a>
                                                <a rel="noreferrer" href="https://r4j68.app.goo.gl/?link=https%3A%2F%2Fthe-diary-of-a-researcher.podbean.com%3Faction%3DopenPodcast%26podcastId%3Dpbblog14834559%26podcastIdTag%3Dmbgmn&dfl=https%3A%2F%2Fthe-diary-of-a-researcher.podbean.com&utm_campaign=pb_web&utm_source=podbean_web&utm_medium=dlink&imv=670&amv=530&isi=973361050&ibi=com.podbean.app.podcast&ipbi=com.podbean.app.podcast&apn=com.podbean.app.podcast" target="_blank"><img alt="Podbean icon." className="no-selection" src={process.env.PUBLIC_URL + '/assets/podcast_podbean.webp'} style={{ width: 30 }}></img></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}
export default BlogPostPage;