import React from "react";

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Link, useNavigate } from "react-router-dom";

import './styles.css';

function Pubcast() {

    const navigate = useNavigate();

    const goTo = (event, location) => {
        event.stopPropagation();
        console.log(location);

        navigate(location);
    }

    const goToExternal = (event, location) => {
        event.stopPropagation();
        window.open(location, '_blank');
    }

    return (

        <div className="pubcast" onClick={(event) => goTo(event, 'pubcast')} style={{cursor: 'pointer'}}>
            <div className="row">
                <div className="col-12 col-lg-9" style={{ display: 'flex', flexDirection: 'column', justifyContent: "space-between" }}>
                    <div>
                        <h1 className="pubcast__title">Pubcast</h1>
                        <h2 className="pubcast__subtitle">The Diary Of A Researcher</h2>
                        <p className="pubcast__body">Unearthing controversies, discoveries and addressing the uncomfortable topics in academia.</p>
                    </div>
                    <div>
                        <p className="pubcast__body">Available on</p>
                        <img alt="listen on Spotify" onClick={(event) => goToExternal(event, 'https://open.spotify.com/show/5LxCfscMprj4VFgd2qNmi4?si=76643cd7caa94223')} src={process.env.PUBLIC_URL + '/assets/podcast_spotify.webp'} style={{ width: 30, marginRight: 15, cursor: 'pointer'}}></img>
                        <img alt="Listen on Apple Podcasts" onClick={(event) => goToExternal(event, 'https://podcasts.apple.com/us/podcast/the-diary-of-a-researcher-by-publett/id1653300115')} src={process.env.PUBLIC_URL + '/assets/podcast_apple.webp'} style={{ width: 30, marginRight: 15, cursor: 'pointer'}}></img>
                        <img onClick={(event) => goToExternal(event, 'https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnBvZGJlYW4uY29tL3RoZS1kaWFyeS1vZi1hLXJlc2VhcmNoZXIvZmVlZC54bWw')} src={process.env.PUBLIC_URL + '/assets/podcast_google.webp'} style={{ width: 30, marginRight: 15, cursor: 'pointer'}}></img>
                        <img onClick={(event) => goToExternal(event, 'https://player.fm/series/3414058')} src={process.env.PUBLIC_URL + '/assets/podcast_player-fm.webp'} style={{ width: 30, marginRight: 15, cursor: 'pointer'}}></img>
                        <img onClick={(event) => goToExternal(event, 'https://music.amazon.com/podcasts/79513bcb-3e3e-41c6-9c87-3fa9a77ee403')} className="no-selection" src={process.env.PUBLIC_URL + '/assets/podcast_amazon.webp'} style={{ width: 30, marginRight: 15, cursor: 'pointer'}}></img>
                        <img onClick={(event) => goToExternal(event, 'https://the-diary-of-a-researcher.podbean.com')} className="no-selection" src={process.env.PUBLIC_URL + '/assets/podcast_podbean.webp'} style={{ width: 30, cursor: 'pointer' }}></img>
                    </div>
                </div>
                <div>
                    {/* <iframe title="Vaccine vs Autism" allowtransparency="true" height="150" width="100%" style={{border: 'none', minWidth: 'min(100%, 430px)'}} scrolling="no" data-name="pb-iframe-player" src="https://www.podbean.com/player-v2/?i=an7u6-13077d9-pb&from=pb6admin&share=1&download=1&rtl=0&fonts=Arial&skin=654771&font-color=auto&logo_link=episode_page&btn-skin=654771"></iframe> */}



                    {/* <iframe title="Vaccine vs Autism" allowtransparency="true" height="150" width="100%" style="border: none; min-width: min(100%, 430px);" scrolling="no" data-name="pb-iframe-player" src="https://www.podbean.com/player-v2/?i=an7u6-13077d9-pb&from=pb6admin&share=1&download=1&rtl=0&fonts=Arial&skin=654771&font-color=auto&logo_link=episode_page&btn-skin=654771"></iframe> */}
                </div>

                {/* <div className="col-12 col-lg-3" style={{ backgroundColor: '#ffffff10', borderRadius: 10, paddingTop: 10, paddingBottom: 10}}>
                    <div className="row">
                        <div className="pubcast__heading__preview col-3 col-lg-12">
                            <video className="pubcast__heading__preview__tile" controls>
                                <source src="assets/pubcast-001.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div className="col-9 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                            <p className="pubcast__body__episode">Vaccines vs Autism</p>
                            <p className="pubcast__body__date">12th August 2022</p>
                            <a href="#" className="pubcast__link">Listen now.</a>
                        </div>
                    </div>
                </div> */}

            </div>
        </div>
    )
}

export default Pubcast;