import React from "react";

import './styles.css'

function BlogIntroduction() {
    return (
        <div className="Blogintroduction">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="Blogintroduction__Title">Blogs</div>
                        <div className="Blogintroduction__Subtitle">As part of our goal to make your academic journey as smooth as possible, we have gathered current and previous academics from different backgrounds to recollect their experiences. As they highlight the DOs, DON’Ts, challenges, and pivotal moments that lead them to a successful career, you will be able to learn from their experience to make the most of yours, both in and when you leave academia. </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BlogIntroduction;