import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Route, Routes } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './App';
import ScrollToTop from "./functions/scrollToTop";

import Home from './pages/Home/Home';
import BlogPage from './pages/Blog/Blog';
import BlogPostPage from './pages/BlogPost/BlogPost';
// import SharePage from './pages/Share/Share';
import BehindTheApp from './pages/BehindTheApp/BehindTheApp';
import Policies from "./pages/Policies/policies";
import PubcastPage from "./pages/Pubcast/Pubcast";
import PubcastDetailPage from "./pages/PubcastDetail/PubcastDetail";
import TermsOfUse from "./pages/Policies/termsofuse";
import Privacy from "./pages/Policies/privacy";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <BrowserRouter>
      <ScrollToTop />
      <Routes>
         <Route path="/" element={<App />}>
            <Route
               index
               element={<Home />}
            />
            <Route path="/behindtheapp" element={<BehindTheApp />} />
            <Route path="/contact" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/blog">
               <Route
                  index
                  element={<BlogPage />}
               />
               <Route path="/blog/:id" element={<BlogPostPage />} />
            </Route>
            <Route path="/pubcast">
               <Route
                  index
                  element={<PubcastPage />}
               />
               <Route path="/pubcast/:uuid" element={<PubcastDetailPage />} />
            </Route>
            <Route path="policies" element={<Policies />}>
               <Route
                  index
                  element={<TermsOfUse />}
               />
               <Route path="termsofuse" element={<TermsOfUse />} />
               <Route path="privacy" element={<Privacy />} />
            </Route>
         </Route>
      </Routes>

   </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
