import React from "react";

import { Link } from "react-router-dom";

import BlogData from "../../data/blogs";

import './styles.css';

function Blog() {
    return (
        <div className="blog">
            <p style={{fontSize: '1.5em', fontWeight: '600', color: '#111111', margin: 0, padding: 0, marginBottom: '.5em'}}>Blogs</p>
            <div style={{margin: 0, padding: 0}}>
            <Link style={{textDecoration: 'none', color: '#444444', margin: 0, padding: 0}} to={`/blog/${BlogData[0].id}`}>
                <img alt={BlogData[0].title} className="blog__image" src={process.env.PUBLIC_URL + '/assets/blogs/' + BlogData[0].image} />

                <div className="blog__content__title">
                    
                    <span className="blog__article__title">{BlogData[0].title}</span>
                    <br /><br />
                    {BlogData[0].content}
                    <br />
                    <Link className="blog__readmore" to={`/blog/${BlogData[0].id}`}>read more</Link>
                    
                </div>
                </Link>
            </div>
            <Link className="blog__moreblogs" to="/blog/">More blogs</Link>
        </div>
    )
}

export default Blog;

