import React, { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

import Blog from "../../components/Blog/Blog";
import Introduction from "../../components/Introduction/introduction";
import Pubcast from "../../components/Pubcast/pubcast";
import PublettApp from "../../components/PublettApp/PublettApp";
import SocialMedia from "../../components/SocialMedia/SocialMedia";
import SubmitAPaper from "../../components/SubmitAPaper/SubmitAPaper";
import Subscriptions from "../../components/Subscriptions/Subscriptions";

import './styles.css';


function Home() {
    const [searchParams, setSearchParams] = useSearchParams();

    const redirectToAppStore = () => {
        const ua = navigator.userAgent;
        try {
            if (/android/i.test(ua)) {
                setTimeout(() => (window.location.replace("https://play.google.com/store/apps/details?id=com.publett")), 1000);
            }
            else if ((/iPad|iPhone|iPod/i.test(ua))) {
                setTimeout(() => (window.location.replace("https://apple.co/3A8Hu1e")), 1000);
            }
        } catch (e) {
            //log error to DB
        }
    }

    useEffect(() => {
        const cmpnValue = searchParams.get('cmpn');

        if (cmpnValue) {
            // fb = facebook, fl = flyer, ig = instagram, li = linkedin, tw = twitter 
            if (cmpnValue == 'fl') {
                //code to set flyer count +1 etc.
            }

            //next delete cmpg param and reset searchParams state before attempting redirect
            //this stops a open appstore loop if the user returns to browser etc.
            searchParams.delete('cmpn');
            setSearchParams(searchParams);

            // redirectToAppStore();
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>Publett | Home</title>
                <meta name="description" content="Publett - Science made a leisure" />
            </Helmet>
            <div className="home__introduction">
                <div className="container">
                    <Introduction />
                </div>
            </div>

            <div className="container">
                <div className="row mt-3 mb-3">
                    <div className="col-12 col-md-12 col-xl-9">
                        <div className="mb-3">
                            <Pubcast />
                        </div>
                        <div className="mb-3">
                            <PublettApp />
                        </div>
                    </div>

                    <div className="col-12 col-xl-3">
                        <div className="row">
                            <div className="mb-3 mb-md-0 mb-xl-3 col-12 col-md-6 col-xs-12 col-xl-12">
                                <SocialMedia />
                            </div>
                            <div className="col--12 col-md-6 col-xl-12">
                                <Blog />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#ffffff' }}>
                <div className="container">
                    <SubmitAPaper />
                </div>
            </div>

            <div className="home__subscription__container">
                <div className="container">
                    <Subscriptions />
                </div>
            </div>
        </>

    )
}
export default Home;