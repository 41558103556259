import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import './styles.css';

function SubmitAPaper() {
    return (
        <div className="submitapaper" style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <div>
                <h1 className="submitapaper__header__title">Submit a paper</h1>
                <h2 className="submitapaper__header__subtitle">Don't let your research get lost in the abyss of publications.</h2>
            </div>
            
            <div style={{ flex: 1}}>
                {/* <p className="submitapaper__body">Submit your work to be reviewed and selected for featuring and inclusion on our app. We offer different routes depending on your requirements.</p> */}
                <p className="submitapaper__body">There are two options to showcase your work, you can submit a paper (FREE) as a suggestion or purchase a guaranteed feature. </p>
                
                {/* <a href="#" className="btn btn-outline-dark btn-sm" data-bs-toggle="modal" data-bs-target="#contactModal">More info</a> */}
                <a href="#" className="submitapaper__link" data-bs-toggle="modal" data-bs-target="#contactModal">More info</a>
            </div>
        </div>
    )
}

export default SubmitAPaper;