import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom';


import BlogData from '../../data/blogs';
import BlogIntroduction from "../../components/BlogIntroduction/BlogIntroduction";

import './styles.css';

function BlogPage() {

    const BlogSection = props => {
        const { count, offset, title } = props;

        return (
            <div className="container">
                <div className="row Blog__Section">
                    <div className="Blog__Section__Title">{title}</div>
                </div>
                <div className="row">
                    {
                        BlogData.slice(offset, count).map((item) => {
                            let { content, duration, id, image, title } = item;

                            let blogImageURL = `../../assets/blogs/${image}`
                            let blogURL = `/blog/${id}`

                            return (
                                <div key={id} className="col-12 col-md-6 col-xl-3 mb-4 ">
                                    <Link style={{ textDecoration: 'none', color: '#333333' }} to={blogURL}>
                                        <div className="card h-100 border-0 shadow-sm">
                                            <img src={blogImageURL} className="card-img-top" alt="..." />
                                            <div className="card-body">
                                                <h5 className="card-title Blog__Card__Title">{title}</h5>
                                                <p className="card-text Blog__Card__Body">{content.substring(0, 250)}</p>
                                            </div>
                                            <div className="card-footer Blog__Card__Footer">
                                                {duration} mins | read now
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>Publett | Blog</title>
                <meta name="description" content="Publett - Science made a leisure" />
            </Helmet>
            <div className="Blog__Introduction">
                <div className="container">
                    <BlogIntroduction />
                </div>
            </div>
            <BlogSection title="Recent Blogs" offset="0" count="4" />
            <BlogSection title="More Blogs" offset="4" count="17" />
        </>
    )
}
export default BlogPage;