import React, { useEffect, useState } from "react";

import './styles.css'

import axios from "axios";

function ContactForm() {

    const [contactState, setContactState] = useState('FILLING');

    useEffect(() => {
        let contactModal = document.querySelector('#contactModal')
        let contactForm = document.querySelector('#contactForm');

        contactModal.addEventListener('hidden.bs.modal', function () {
            if (contactForm) {
                contactForm.reset();
            }
            setContactState('FILLING');
        })

        return (
            contactModal.removeEventListener('hidden.bs.modal', function () { })
        )
    })

    function handleSubmit(e) {
        e.preventDefault();

        let name = document.getElementById('contactFormName');
        let email = document.getElementById('contactFormEmail');
        let message = document.getElementById('contactFormMessage');
        let approvedsuggestion = document.getElementById('contactFormApprovedSuggestion');
        let newsletter = document.getElementById('contactFormNewsLetter')
        let featurepricing = document.getElementById('contactFormFeaturepricing')
        let institutional = document.getElementById('contactFormInstitutional')
        let pubference = document.getElementById('contactFormPubference')

        // console.log(name, email, message, approvedsuggestion, newsletter, featurepricing, institutional, pubference);

        let error = false;

        if (name.value === undefined || name.value === "") {
            name.style.border = "1px solid purple";
            error = true;
        }

        if (email.value === undefined || email.value === "") {
            email.style.border = "1px solid purple";
            error = true;
        }

        if (!error) {
            setContactState('SENDING')

            try {
                const messageData = {
                    "uName": name.value,
                    "uEmail": email.value,
                    "uMessage": message.value,
                    "uApprovedsuggestion": approvedsuggestion.checked,
                    "uNewsletter": newsletter.checked,
                    "ufeaturepricing": featurepricing.checked,
                    "uInstitutional": institutional.checked,
                    "uPubference": pubference.checked
                }
                console.log(messageData);
                // var qs = require('qs');
                // let data = qs.stringify(messageData)
                axios({
                    method: 'post',
                    url: '/usermessage.php',
                    headers: {
                        "Content-Type": 'text/json; charset=UTF-8',
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
                    },
                    data: messageData
                })
                    .then((response) => {
                        if (response.data.result === "OK") {
                            setContactState("SENT");
                        }
                        else {
                            setContactState("ERROR");
                        }
                    }).catch(() => {
                        setContactState("ERROR");
                    })
            }
            catch (e) {
                setContactState("ERROR");
                alert(e)
            }
        }
    }

    const ContactForm = () => {
        return (
            <form onSubmit={handleSubmit} name="contactForm" id="contactForm">
                <p>There are two choices, you can submit a paper (FREE) as a suggestion<sup>*</sup> which is not guaranteed a feature, however if you want a guaranteed feature<sup>*</sup> within a certain reasonable time frame, you can purchase this by checking the box below. This comprises of inclusion in our catalogue and as part of our free weekly feature (subject to availability).
                </p>
                <div className="mb-3">
                    <input type="text" tabIndex="1" className="form-control" id="contactFormName" placeholder="Name" required />
                </div>
                <div className="mb-3">
                    <input type="email" tabIndex="2" className="form-control" id="contactFormEmail" placeholder="email@example.com" required />
                </div>
                <div className="mb-3">
                    <textarea className="form-control" tabIndex="3" id="contactFormMessage" rows="3" placeholder="Publication Suggestion/Question"></textarea>
                    <div className="contact__letmeknow__check"><input tabIndex="4" id="contactFormApprovedSuggestion" type="checkbox" /><label htmlFor="contactFormApprovedSuggestion">&nbsp;Let me know if my suggestion was approved.</label></div>
                </div>
                <div className="contact__requests">
                    <h5 className="contact__section__heading">Join the mailing list</h5>
                    <div><input tabIndex="5" id="contactFormNewsLetter" type="checkbox" /><label htmlFor="contactFormNewsLetter">&nbsp;Be in the know, sign up to our mailing list<sup>**</sup></label></div>
                    <br />
                    <h5 className="contact__section__heading">Any other information?</h5>
                    <div><input tabIndex="6" id="contactFormFeaturepricing" type="checkbox" /><label htmlFor="contactFormFeaturepricing">&nbsp;Guaranteed feature pricing</label></div>
                    <div><input tabIndex="7" id="contactFormInstitutional" type="checkbox" /><label htmlFor="contactFormInstitutional">&nbsp;Institutional packages</label></div>
                    <div><input tabIndex="8" id="contactFormPubference" type="checkbox" /><label htmlFor="contactFormPubference">&nbsp;Pubference</label></div>
                </div>
                <div className="contact__submit">
                    <button tabIndex="9" type="submit" className="btn btn-primary submit_button">Submit</button></div>
                <br />
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button collapsed" style={{ fontSize: 12 }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <sup>*</sup>&nbsp;Paper Requirements
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p style={{ fontSize: 12 }} >Compulsory requirements: Publications must be open-source and original research. Preferential requirements: published within the last year - some exceptions will be made for original seminal research papers. Restrictions: no direct covid-related research.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" style={{ fontSize: 12 }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTow">
                                <sup>**</sup>&nbsp;Mailing list
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p style={{ fontSize: 12 }} >By signing up to our mailing list, you agree to our privacy policy.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    const ContactSending = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 30 }}>
                <div className="spinner-border text" role="status">
                    <span className="visually-hidden">Sending...</span>
                </div>
                <span style={{ paddingLeft: 20, fontSize: 14, color: '#444444' }}>One moment, we're sending your request.</span>
            </div>
        )
    }

    const ContactSent = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 30 }}>
                Thanks! We've received your request.
            </div>
        )
    }
    
    const ContactError = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 30 }}>
                Oops, something went wrong!
            </div>
        )
    }

    return (
        <div className="modal fade" id="contactModal" tabIndex="-1" aria-labelledby="exampcontactModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen-lg-down modal-lg  modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content contact_gradient">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampcontactModalLabel">Contact or Submit A Paper</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                    </div>

                    <div className="modal-body contact__body">
                        {contactState === "FILLING" && <ContactForm />}
                        {contactState === 'SENDING' && <ContactSending />}
                        {contactState === "SENT" && <ContactSent />}
                        {contactState === "ERROR" && <ContactError />}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactForm;