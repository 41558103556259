import React from "react";

import { NavLink } from "react-router-dom";

import './styles.css';

function Footer() {
    const year = new Date().getFullYear();

    return (
        <div className="Footer">
            <div className="container">
                <div className="Footer__Container">
                    <div className="Footer__Copyright">
                        2020-{year} &copy; Publett Limited, London, UK. Company reg. no. 1382194
                    </div>
                    <div className="Footer__Policies">
                        <NavLink
                            exact={'true'}
                            to='/policies/termsofuse'
                            className={(navData) => (navData.isActive ? 'Footer__Link Footer__Link--Active' : 'Footer__Link')}
                        >Terms of use</NavLink>
                        <NavLink
                            exact={'true'}
                            to='/policies/privacy'
                            className={(navData) => (navData.isActive ? 'Footer__Link Footer__Link--Active' : 'Footer__Link')}
                        >Privacy</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;