import { useState } from 'react'
import { Helmet } from 'react-helmet-async'

// import OliviaRissland from '../../assets/oliviaRissland.jpeg'
// import HardToRead from '../../assets/harderToRead.jpeg'
// import TeamPic from '../../assets/team.jpeg'
// import NoAccess from '../../assets/NoAccess.jpg'

import Modal from 'react-bootstrap/Modal'

import BehindTheAppIntroduction from '../../components/BehindTheAppIntroduction/BehingTheAppIntroduction';

import './styles.css';

export default function BehindTheApp() {

    const goToURL = (url) => {
        window.open(url);
    }

    const [showHardToRead, setShowHardToRead] = useState(false);
    const [showEveryday, setShowEveryday] = useState(false);
    const [showAccess, setShowAccess] = useState(false);

    const handleHardToReadClose = () => setShowHardToRead(false);
    const handleHardToReadShow = () => setShowHardToRead(true);
    const handleEverydayClose = () => setShowEveryday(false);
    const handleEverydayShow = () => setShowEveryday(true);
    const handleAccessClose = () => setShowAccess(false);
    const handleAccessShow = () => setShowAccess(true);

    const ArticleImage = (props) => {

        const { imageName, color = 'Peach', direction = 'Left', handle = null} = props;

        let colorStyle = `Behindtheapp__Article__Image Behindtheapp__Article__Image--${color}`;
        let directionStyle = `Behindtheapp__Article__Image__Direction Behindtheapp__Article__Image__Direction--${direction}`;
        let directionStyleDisabled = `${directionStyle} Behindtheapp__Article__Image__Direction--Disabled`;

        if(handle) {
            return (
                <div className={directionStyle} onClick={handle}>
                    <div className={colorStyle}>
                        <img alt="" src={process.env.PUBLIC_URL + 'assets/' + imageName} className="Behindtheapp__Article__Image__Size" />
                    </div>

                    <div className="Behindtheapp__Article__Image__Link">
                        Expand image &#8599;
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className={directionStyleDisabled}>
                    <div className={colorStyle}>
                        <img alt="" src={process.env.PUBLIC_URL + 'assets/' + imageName} className="Behindtheapp__Article__Image__Size" />
                    </div>
                </div>
            )
        }
}

return (
    <>
        <Helmet>
            <title>Publett | Behind the app</title>
            <meta name="description" content="Publett - Science made a leisure" />
            <meta name="keywords" content="Publett, science, growth" />
        </Helmet>
        
        <BehindTheAppIntroduction />

        {/* hard to read */}
        <div className="Behindtheapp__Article--Dark">
            <div className="container">
                <div className="row Behindtheapp__Article">
                    <div className="col-10 offset-1 col-md-7 offset-md-1">
                        <h2 className="Behindtheapp__Article__Title Behindtheapp__Article__Title--Peach">Science is getting harder to read</h2>
                        <div className="Behindtheapp__Article__Image--Left">
                            <ArticleImage imageName="harderToRead.jpeg" color={'Peach'} direction={'Right'} handle={handleHardToReadShow} />
                        </div>
                        <p className="Behindtheapp__Article__Body">We wanted something that we can access in our leisure time and still be productive, something of quality, no chit-chat of a podcast or heavily opinionated.</p>
                        <p className="Behindtheapp__Article__Body Behindtheapp__Article__Body--Strong">Something so solid that it can even be considered reference worthy.</p>
                        <button className="Behindtheapp__External__Link" onClick={() => goToURL('https://www.nature.com/articles/nature.2017.21751')} style={{ marginTop: 20 }}>View the article on Nature</button>
                    </div>
                    <div className="col-3 col-sm-4 col-lg-3 Behindtheapp__Article__Image--Hidden ">
                        <ArticleImage imageName="harderToRead.jpeg" color={'Peach'} direction={'Right'} handle={handleHardToReadShow} />
                    </div>
                </div>
            </div>
        </div>

        {/* become a well rounded scientist */}
        <div className="Behindtheapp__Article">
            <div className="container">
                <div className="row Behindtheapp__Article">
                    <div className="col-3 offset-1 col-lg-3 Behindtheapp__Article__Image--Hidden ">
                        <ArticleImage imageName="oliviaRissland.jpeg" color={'Purple'} direction={'Left'} handle={handleEverydayShow} />
                    </div>

                    <div className="col-10 offset-1 col-md-7 offset-md-0">
                        <h2 className="Behindtheapp__Article__Title Behindtheapp__Article__Title--Purple">Become a well-rounded scientist</h2>
                        <div className="Behindtheapp__Article__Image--Right">
                            <ArticleImage imageName="oliviaRissland.jpeg" color={'Purple'} direction={'Left'} handle={handleEverydayShow} />
                        </div>
                        <p className="Behindtheapp__Article__Body">You may not find a particular paper, but you get to open your eyes to general research, listen to something you ordinarily wouldn't choose, get ideas for your research and be up to date.</p>
                        <p className="Behindtheapp__Article__Body Behindtheapp__Article__Body--Strong">You can be like Olivia Rissland, the molecular biologist that read a paper every day for 899 days:</p>
                        <p className="Behindtheapp__Blockquote">"It's made me a much more well-rounded scientist" - Olivia Rissland</p>
                        <button className="Behindtheapp__External__Link" onClick={() => goToURL('https://www.natureindex.com/news-blog/this-scientist-read-research-academic-paper-every-day-what-she-learned')} style={{ marginTop: 20 }}>Read her story on Nature Index</button>
                    </div>

                </div>
            </div>
        </div>

        {/* inaccessibility of science */}
        <div className="Behindtheapp__Article--Dark">
            <div className="container">
                <div className="row Behindtheapp__Article">
                    <div className="col-10 offset-1 col-md-7 offset-md-1">
                        <h2 className="Behindtheapp__Article__Title Behindtheapp__Article__Title--Pink">Inaccessibility of science</h2>
                        <div className="Behindtheapp__Article__Image--Left">
                            <ArticleImage imageName="NoAccess.jpg" color={'Pink'} direction={'Right'} handle={handleAccessShow} />
                        </div>
                        <p className="Behindtheapp__Article__Body">As made famous by an academic meme, access to science is often expensive or an access priviledge few have. Therefore, another leg of our ethos was research accessibility, so we used Frontiers as the source for our articles (they have no affiliation with us).</p>
                        <p className="Behindtheapp__Article__Body Behindtheapp__Article__Body--Strong">All our Publetts are DOI-linked directly to the full open-source article on the publisher's site.</p>
                        <button className="Behindtheapp__External__Link" onClick={() => goToURL('https://www.nature.com/articles/356739a0')} style={{ marginTop: 20 }}>View (the free) article on Nature</button>
                    </div>
                    <div className="col-3 col-sm-4 col-lg-3 Behindtheapp__Article__Image--Hidden ">
                        <ArticleImage imageName="NoAccess.jpg" color={'Pink'} direction={'Right'} handle={handleAccessShow} />
                    </div>
                </div>
            </div>
        </div>

        <div className="Behindtheapp__Team__Wrap">
            <div className="container">
                <div className="row">
                    <div className="col-3 offset-1 Behindtheapp__Article__Image--Hidden ">
                        <ArticleImage imageName="team.jpeg" color={'Amber'} direction={'Left'} />
                    </div>
                    <div className="col-10 offset-1 col-md-8 offset-md-0">
                        <h2 className="Behindtheapp__Team__Title">Our team</h2>
                        <div className="Behindtheapp__Article__Image--Right">
                            <ArticleImage imageName="team.jpeg" color={'Amber'} direction={'Left'} />
                        </div>
                        <p className="Behindtheapp__Article__Body" >We are currently a small start-up team based in a London home office with one senior developer, two junior writers and an incredible group of freelance voice actors from around the world.
                        </p>
                        <p className="Behindtheapp__Article__Body" >We are committed to bringing you even more content that's not just limited to Publetts, which means we would be <b>looking to grow our team</b>. If you think there's a place for you here where we are passionate about learning and achieving better workplace practices, use our contact form to <b>drop us a line</b>.
                        </p>
                        <p className="Behindtheapp__Article__Body">Thank you for visiting the first version of us before we evolve.</p>
                    </div>
                </div>
            </div>
        </div>

        <Modal show={showHardToRead} onHide={handleHardToReadClose} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body><img alt="" src={process.env.PUBLIC_URL + 'assets/harderToRead.jpeg'} width="100%" /></Modal.Body>
        </Modal>

        <Modal show={showEveryday} onHide={handleEverydayClose} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body><img alt="" src={process.env.PUBLIC_URL + 'assets/oliviaRissland.jpeg'} width="100%" /></Modal.Body>
        </Modal>

        <Modal show={showAccess} onHide={handleAccessClose} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body><img alt="" src={process.env.PUBLIC_URL + 'assets/NoAccess.jpg'} width="100%" /></Modal.Body>
        </Modal>
    </>
);
}