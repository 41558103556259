import react from 'react'
import { Outlet, Link } from "react-router-dom";
import { useState } from 'react'
import { Helmet } from 'react-helmet-async';

import '../../App.css';
import './styles.css';

import 'bootstrap/dist/css/bootstrap.min.css';

// import Carousel from 'react-bootstrap/Carousel'
// import Card from 'react-bootstrap/Card'
// import Button from 'react-bootstrap/Button'
// import Modal from 'react-bootstrap/Modal'
// import Form from 'react-bootstrap/Form'
// import Badge from 'react-bootstrap/Badge'

import Nav from 'react-bootstrap/Nav'
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import AppleAppStore from '../assets/apple_app_store.svg'
// import GooglePlayStore from '../assets/google_play_store.svg'
// import TwitterLogo from '../assets/twitter_logo.svg'
// import InstagramLogo from '../assets/instagram_logo.png'
// import LinkedInLogo from '../assets/linkedin_logo.png'
// import FacebookLogo from '../assets/facebook_logo.png'
// import { act } from 'react-dom/test-utils';

import { NavLink } from 'react-router-dom';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function Welcome() {

    const [show, setShow] = useState(false);
    const [activeDocument, setActiveDocument] = useState();




    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePathChange = () => {

    }

    var thePath = window.location.href;
    var activeKey = thePath.substring(thePath.lastIndexOf('/') + 1)

    return (
        <>
            <Helmet>
                <title>Publett | Policies</title>
                <meta name="description" content="Publett - Science made a leisure" />
                <meta name="keywords" content="Publett, science, growth" />
            </Helmet>
            <Container>
                <style type="text/css">
                    {`
            .active {
                color: #f37c7c;
                text-decoration: none;
                padding: 10px;
            }
            .link {
                color: #333333;
                text-decoration: none;
                padding: 10px;
            }
            `}
                </style>
                <Row>
                    <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ padding: 30 }}>
                                <h1>Policies</h1>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Nav className="Policies__Nav__Bar">
                                    <NavLink
                                        exact={'true'}
                                        to='/policies/termsofuse'
                                        className={(navData) => (navData.isActive ? 'Policies__Active__Link' : 'Policies__Link')}
                                    >
                                        Terms of use
                                    </NavLink>
                                    <NavLink
                                        exact={'true'}
                                        to='/policies/privacy'
                                        className={(navData) => (navData.isActive ? 'Policies__Active__Link' : 'Policies__Link')}
                                    >
                                        Privacy
                                    </NavLink>
                                </Nav>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <Outlet />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}