import React from "react";

import './styles.css';

function Subscriptions() {
    return (
        <>

            <div className="container">
                <div className="subscriptions">
                    <div>
                        <h1>Plans</h1>
                    </div>
                    <div className="table-responsive-xl">
                        <table className="table table-striped table-borderless align-middle">
                            <thead>
                                <tr>
                                    <th>Feature</th>
                                    <th>Free</th>
                                    <th>Monthly</th>
                                    <th>Annual</th>
                                    <th>Enterprise</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr scope="row">
                                    <td>Price</td>
                                    <td>-</td>
                                    <td>£5.99 pcm</td>
                                    <td>£64.99 pa</td>
                                    <td>Give your students and staff instant access</td>
                                </tr>
                                <tr scope="row">
                                    <td>Weekly feature</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                </tr>
                                <tr scope="row">
                                    <td>Monthly Picks</td>
                                    <td>&nbsp;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                </tr>
                                <tr scope="row">
                                    <td>Expert Pics</td>
                                    <td>&nbsp;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                </tr>
                                <tr scope="row">
                                    <td>Pubcast Early Access <span className="badge Subscription__New__Pill">New </span></td>
                                    <td>&nbsp;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                </tr>
                                <tr scope="row">
                                    <td>Highlighting <span className="badge Subscription__ComingSoon__Pill">Coming soon</span></td>
                                    <td>&nbsp;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                </tr>
                                <tr scope="row">
                                    <td>Queue Publetts</td>
                                    <td>&nbsp;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                </tr>
                                <tr scope="row">
                                    <td>Save Publetts</td>
                                    <td>&nbsp;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                </tr>
                                <tr scope="row">
                                    <td>Offline mode <span className="badge Subscription__ComingSoon__Pill">Coming soon</span></td>
                                    <td>&nbsp;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                </tr>
                                <tr scope="row">
                                    <td>History <span className="badge Subscription__ComingSoon__Pill">Coming soon</span></td>
                                    <td>&nbsp;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                </tr>
                                <tr scope="row">
                                    <td>Referencing integration <span className="badge Subscription__ComingSoon__Pill">Coming soon</span></td>
                                    <td>&nbsp;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                    <td>&#x2605;</td>
                                </tr>
                                <tr scope="row">
                                    <td>Single sign-on</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&#x2605;</td>
                                </tr>
                                <tr scope="row">
                                    <td>Institution catalogue</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&#x2605;</td>
                                </tr>
                                <tr scope="row">
                                    <td>User administration</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&#x2605;</td>
                                </tr>
                                <tr scope="row">
                                    <td>Analytics</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&#x2605;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="Subscriptions__Footer">
                        <p>Grab a free trial, no credit-card required, simply install the app to get started.</p>
                        <p>Pubcast is also available via other podcast apps.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Subscriptions;