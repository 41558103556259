import React from "react";
import { useState, useEffect } from "react";
// import { createPopper } from '@popperjs/core';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import FB_Logo from '../../assets/logos/facebook_logo.png';
import IG_Logo from '../../assets/logos/instagram_logo.png';
import LI_Logo from '../../assets/logos/linkedin_logo.png';
import TW_Logo from '../../assets/logos/twitter_logo.svg';
import YT_Logo from '../../assets/logos/youtube_logo.svg';

import './styles.css';

const goToURL= (url) => {
    window.open(url);
}


function SocialMedia() {

    useEffect(() => {
        //fetch list of media
        const appId = 7839827006059615;
        // const accessToken = "IGQVJVaUdFVlEyaDNIbkdvbkdRMkJoNUFxcnAyNEk2aEpRamJjSERRdHFFbGxSRGRSZAEJYR3RMX3NmUjhoMnZABREJRTHFBRlJSaG5laFlXNlpXTFR4V3Q3ZA3E2cVRCQjNXY0JOdkp1b0lrSVRndW1QWQZDZD";
        // const accessToken = "IGQVJXRXg1Mkx4S1pkd3dOSF9IUFpvMFpQWVh3Umx0WWlDM1NrcE12TG1mb01ZAeXJ6cGEzUlJLZA3hZAaWtYYU5BMm5ISmdZAZAjMwLUlHNE9iYWVXUXdUNTZAoTzRyUi0xZAVJSck0tR3oyZAEZASM1E4WG9aYQZDZD";
        // const accessToken = "IGQVJYVjNhOXFDODUxWHJmaExfZA01HVTkzQTRZATmNzb1kwTVNpY3BCd3hnU2FGaGxZAXy1KcjRwZA0lPdDRnQjBDUDFwdXJTZAVJZAQ0NwTEdjSnhPS0xidUI5S2NBZAjZALVXM2T2xNRmFpZA3BzY0ZAqRl9YRgZDZD";
        // const accessToken = "IGQVJYX1ZAPWTBkcFJnR0FPTGFNc1JYQldjcS1ZANHNsSVkyTEs3XzRIc3otcHF2NmxjSjgzVlhGSFJVZAUVLTkR5WFp6Ti16ZA1FiX1ZAiVGxXSEtLWWdtM2NEemw2RGJiU2FKMThEeE5tRDRvRllMNTZAFOQZDZD";
        //const accessToken = "IGQVJXVXR1dXJwQUtqMWk3bW80Y2tWZATRBaF9BNTZAlS0JvdjVER01DWS14cS0tS3ZATYVlPZADdtaFpQRVN5enNZAVW50cTdLM2JJRjRSTGtYVTROZA1VremVUajJxS2VvYmxWdVJldkk3ZAmh2Y1VNdEI2UgZDZD";
        // const accessToken = "IGQWRPOEFiZAGR6NDNnM0dwTmZA1U1hCMDFPYmk3d2tyQmtiWE5zazU5NHNRWks2cVZAaSWEyRmNaRnc1THFpcW1zeUlWQXJ1NkU0LWVCRHNkMmtjbjZA1YkZAlUTBXTWRLS3I3aFpjNDNlWVFnXzdqbXVQOG9UczhMejgZD";
        const accessToken ="IGQWRPaGZArbWptcklxSHRLelpCVVRzWVFpc01kNF9kdThwVC1hc3RQRFg2MVhzQ0xLRExTSEcteXpqZA3JuTDM2N0FIRC15aUV4MHRqMUJHcmZAEZAXpJdldrVXVQQ2lBT2lvandCdUxVMmQ3MkFzNlV1Q1pnSXVOLWMZD";
        const mediaListFields = "media,id,username";

        const mediaListUrl = "https://graph.instagram.com/" + appId + "?fields=" + mediaListFields + "&access_token=" + accessToken;

        // console.log(mediaListUrl);

        fetch(mediaListUrl)
            .then((response) => response.json())
            .then((data) => {
                const latestPostId = data.media.data[0].id;
                const latestPostFields = "id,media_type,media_url,caption,thumbnail_url";
                // setLatestPostId(latestPostId);

                const latestPostUrl = "https://graph.instagram.com/" + latestPostId + "?fields=" + latestPostFields + "&access_token=" + accessToken;
                fetch(latestPostUrl)
                    .then((response) => response.json())
                    .then((data) => {
                        // console.log(data)
                        setLatestPostInfo(data);
                        setIsLoading(false);
                    })

                //https://graph.instagram.com/17968455886681360?fields=id,media_type,media_url,caption,thumbnail_url&access_token=IGQVJVaUdFVlEyaDNIbkdvbkdRMkJoNUFxcnAyNEk2aEpRamJjSERRdHFFbGxSRGRSZAEJYR3RMX3NmUjhoMnZABREJRTHFBRlJSaG5laFlXNlpXTFR4V3Q3ZA3E2cVRCQjNXY0JOdkp1b0lrSVRndW1QWQZDZD

                // console.log(latestPostId);



                // console.log(data);
                //setMediaList(response);
            });
    }, []);


    // const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    //const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))


    const [isLoading, setIsLoading] = useState(true);
    const [mediaList, setMediaList] = useState(null);
    // const [latestPostId, setLatestPostId] = useState(null);
    const [latestPostInfo, setLatestPostInfo] = useState(null);

    var igMediaType = latestPostInfo === null ? 'none' : latestPostInfo.media_type;
    var IGmediaUrl = latestPostInfo === null ? 'none' : latestPostInfo.media_url;

    const LoadingView = () => (
        <div className="socialmedia__image" >
            <div className="spinner-grow text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )

    const MediaView = () => (
        <>
        {/* <div style={{backgroundColor: '#fafafa', borderRadius: 5}}> */}
            {/* <div className="col-md-8 offset-md-2 col-xl-12 offset-xl-0"> */}
                {
                    latestPostInfo.media_type == "VIDEO" ? (
                        <video className="socialmedia__post" controls poster={latestPostInfo.thumbnail_url}>
                            <source src={latestPostInfo.media_url} type="video/mp4" />
                            <p style={{ margin: 0, padding: 0 }}>Your browser does not support the video tag.</p>
                        </video>
                    ) : (
                        <img height="250" className="socialmedia__post" src={latestPostInfo.media_url} />
                    )
                }
            {/* </div> */}
            

        {/* </div> */}
        {/* <div className="col-lg-12 pt-2 socialmedia__ig__link">Our latest post from Instagram</div> */}
        </>
    )

    return (
        <div className="socialmedia">
            <h1 className="socialmedia__title">Social</h1>
            <h2 className="socialmedia__subtitle">Connect to meet the community of Publetters</h2>
            {
                isLoading ? (
                    <LoadingView />
                ) : (
                    <>
                        <MediaView />
                    </>

                )
            }
            <p className="socialmedia__message">Get our latest announcements, updates and offers!</p>

            <div className="socialmedia__icons">
                <div className="col-12 socialmedia__icons">
                    <img className="socialmedia__icon__link" title="View dr.publett on Instagram." src={IG_Logo} alt="Instagram Logo" onClick={() => goToURL('https://instagram.com/dr.publett')} />
                    <img className="socialmedia__icon__link" title="View Publett on LinkedIn." src={LI_Logo} alt="LinkedIn Logo" onClick={() => goToURL('https://www.linkedin.com/company/publett-limited')} />
                    <img className="socialmedia__icon__link" title="View Publett on YouTube." src={YT_Logo} alt="YouTube Logo" onClick={() => goToURL('https://www.youtube.com/channel/UCJ4SRvEGWbpPrCgtEKI9Jtw')} />
                    <img className="socialmedia__icon__link" title="View Publett on twitter." src={TW_Logo} alt="Twitter Logo" onClick={() => goToURL('https://mobile.twitter.com/publett')} />
                    <img className="socialmedia__icon__link" title="View Publett on Facebook." src={FB_Logo} alt="Facebook Logo" onClick={() => goToURL('https://facebook.com/Publett')} />
                </div>
            </div>
        </div>


    )
}

export default SocialMedia;