import React from "react";

import { Link, NavLink } from "react-router-dom";

import PublettLogoSVG from '../../react-assets/publettLogo-01.svg';

import './styles.css';

function Navigation() {
   return (
      <nav className="navbar sticky-top Navigation">
         <div className="container">
            <Link className="Navigation__Link__Publett" to="/">
               <img alt="Publett logo." src={PublettLogoSVG} />
               <p>Publett</p>
            </Link>

            <div>
               <NavLink
                  exact={'true'}
                  to='/blog'
                  className={(navData) => (navData.isActive ? 'Navigation__Link Navigation__Link--Active' : 'Navigation__Link')}
               >Blog</NavLink>

               <NavLink
                  exact={'true'}
                  to='/behindtheapp'
                  className={(navData) => (navData.isActive ? 'Navigation__Link Navigation__Link--Active' : 'Navigation__Link')}
               >Behind the app</NavLink>

               <NavLink
                  exact={'true'}
                  to='/contact'
                  className={(navData) => (navData.isActive ? 'Navigation__Link Navigation__Link--Active' : 'Navigation__Link')}
                  data-bs-toggle="modal" data-bs-target="#contactModal"
               >Contact</NavLink>
            </div>
         </div>
      </nav>
   )
}
export default Navigation;