import { useState } from 'react';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Badge from 'react-bootstrap/Badge'
import Ratio from 'react-bootstrap/Ratio'

import './styles.css';

// import Interdisciplinary from 'assets/interdisciplinary.svg'
// import Grow from 'assets/grow.svg'
// import Wow from 'assets/wow.svg'

export default function AppBenefit() {

   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   return (
      <>
         <style type="text/css">
            {`
         .btn-hit {
            color: purple;
            border: 1px solid purple;
            font-size: 10pt;
         }
         .btn-hit:hover {
            color: #ffffff;
            background-color: purple;
         }
         `}
         </style>
         {/* <div> */}
         <div className="appbenefit">
            <div className="row">
               <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                  <div className="row">
                     <div className="col-3 col-sm-2 col-md-3 col-lg-4" style={{marginBottom: 15}}>
                        <img src="assets/save3.svg" />
                     </div>
                     <div className="col-9 col-sm-10 col-md-12 col-lg-12">
                        <p className="appbenefit__card__title">Save time</p>
                        <p className="appbenefit__card__text__p">All our Publetts are under 10 minutes</p>
                     </div>
                  </div>
               </div>

               <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                  <div className="row">
                     <div className="col-3 col-sm-2 col-md-3 col-lg-4" style={{marginBottom: 15}}>
                        <img src="assets/grow3.svg" />
                     </div>
                     <div className="col-9 col-sm-10 col-md-12 col-lg-12">
                        <p className="appbenefit__card__title">Grow</p>
                        <p className="appbenefit__card__text__p">Broaden your scope,
                           gain<span className="appbenefit__card__text__p__span--bold"> confidence</span>,
                           get ideas for your own research
                        </p>
                        <p className="appbenefit__card__text__p"></p>
                        <p className="appbenefit__card__text__p"></p>
                     </div>
                  </div>
               </div>

               <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                  <div className="row">
                     <div className="col-3 col-sm-2 col-md-3 col-lg-4" style={{marginBottom: 15}}>
                        <img src="assets/interdisciplinary3.svg" />
                     </div>
                     <div className="col-9 col-sm-10 col-md-12 col-lg-12">
                        <p className="appbenefit__card__title">Interdisciplinary</p>
                        <p className="appbenefit__card__text__p">Our collection of interdisciplinary fields of research is continuously growing</p>
                     </div>
                  </div>
               </div>

               <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                  <div className="row">
                     <div className="col-3 col-sm-2 col-md-3 col-lg-4" style={{marginBottom: 15}}>
                        <img src="assets/wow3.svg" />
                     </div>
                     <div className="col-9 col-sm-10 col-md-12 col-lg-12">
                        <p className="appbenefit__card__title">Impress</p>
                        <p className="appbenefit__card__text__p">WOW your peers, teachers, and employers!</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {/* <Card className="appbenefit__card border-0">
               <Ratio aspectRatio="21x9">
                  <img src="assets/wow.svg" />
               </Ratio>
               <Card.Body>
                  <Card.Title className="appbenefit__card__title">Save time</Card.Title>
                  <Card.Text>
                     <p className="appbenefit__card__text__p">All our Publetts are <span className="appbenefit__card__text__p__span--bold">under 10 minutes</span></p>
                  </Card.Text>
               </Card.Body>
            </Card> */}

         {/* <Card className="appbenefit__card border-0">
               <Ratio aspectRatio="21x9">
                  <img src="assets/grow.svg" />
               </Ratio>
               <Card.Body>
                  <Card.Title className="appbenefit__card__title">Grow</Card.Title>
                  <Card.Text>
                     <p className="appbenefit__card__text__p">Broaden your<span className="appbenefit__card__text__p__span--bold"> scope</span></p>
                     <p className="appbenefit__card__text__p">Gain<span className="appbenefit__card__text__p__span--bold"> confidence</span></p>
                     <p className="appbenefit__card__text__p">Get<span className="appbenefit__card__text__p__span--bold"> ideas</span> for your own research</p>
                  </Card.Text>
               </Card.Body>
            </Card> */}

         {/* <Card className="appbenefit__card border-0">
               <Ratio aspectRatio="21x9">
                  <img src="assets/interdisciplinary.svg" />
               </Ratio>
               <Card.Body>
                  <Card.Title className="appbenefit__card__title">Interdisciplinary</Card.Title>
                  <Card.Text>
                     <p className="appbenefit__card__text__p">Our collection of<span className="appbenefit__card__text__p__span--bold"> interdisciplinary</span> fields of research is continuously growing</p>
                  </Card.Text>
               </Card.Body>
            </Card> */}

         {/* <Card className="appbenefit__card border-0">
               <Ratio aspectRatio="21x9">
                 <img src="assets/wow.svg" />
               </Ratio>
               <Card.Body>
                  <Card.Title className="appbenefit__card__title">Impress</Card.Title>
                  <Card.Text>
                     <p className="appbenefit__card__text__p"><span className="welcomebenefitcard__card__text__p__span--bold">Wow</span> your peers, teachers, and employers!</p>
                  </Card.Text>
               </Card.Body>
            </Card> */}

         <Modal show={show} centered="true" onHide={handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>Catagories</Modal.Title>
            </Modal.Header>

            <Modal.Body>
               <p>Animals in Science</p>
               <p>Cancer</p>
               <p>Cell BioChemistry</p>
               <p>Computer Science <Badge bg="info">New</Badge></p>
               <p>Dermatology</p>
               <p>Environmental Science</p>
               <p>Genetics</p>
               <p>Health and Fitness</p>
               <p>Immunology</p>
               <p>Methods and Materials</p>
               <p>Microbiology</p>
               <p>Neuroscience</p>
               <p>Paediatric <Badge bg="info">New</Badge></p>
               <p>Pharmacology</p>
               <p>Plant Science</p>
               <p>Psychology</p>
            </Modal.Body>
         </Modal>
         {/* </div> */}
      </>
   );
}