export default function TermsOfUse() {
    return (
        <main style={{ padding: 30, wordBreak: 'break-word', overflowWrap: 'break-word', wordWrap: 'break-word'}}>
            <h3 style={{ marginBottom: 30 }}>Publett Terms of Use</h3>
            <ol>
                <li>
                    <h4>Object and validity:</h4>
                </li>
                <ol type="a">
                    <li>
                        <p>The company: </p>
                        <p>Publett Limited, London, UK</p>
                        {/* <p>Persons of significant control: Aakila Sammy and Matthew O'Neill</p> */}
                        <p>admin@publett.com</p>
                        <p>HMRC company no. 13821694</p>
                        <p>Herein referred to as Publett, runs a platform for mobile listening and reading on a mobile application (App). Their writers summarise the content of scientific journals without changing the meaning so you can listen to under 10 minutes or read. </p>
                    </li>
                    <li><p>Publett acknowledges its terms and condition at the time of registration and does not any other unless agreed to by writing. </p></li>
                    <li><p>No separate contract text is provided for this App, and the agreement is based on these terms and conditions. You accept the legitimacy and applicability of these T&amp;Cs when registering and buying a subscription. </p></li>
                </ol>


                <li>
                    <h4>Service and rights</h4>
                </li>
                <ol type="a">
                    <li>
                        <p>Publett provides audio and text summaries of scientific publications, which you can listen to in under 10minutes. You can create a queue list for current listening or a saved for later list. You can choose your favourite categories to display. </p>
                    </li>
                    <li>
                        <p>There is a difference between the free, trial and paid accounts. The licence is limited to the duration of these subscriptions. </p>
                    </li>
                    <li>
                        <p>The rights to Publett service and content is distributed and remain with Publett. You are only granted time-limited and non-exclusive use. </p>
                    </li>
                    <li>
                        <p>All branding (logos), distinctive features, tradename, domain names are the sole property of Publett, and the time-limited and non-exclusive license does not grant you the right to use these in a commercial or non-commercial capacity. </p>
                    </li>
                    <li>
                        <p>If you do not use a paid service, Publett is allowed to show you promotional content. </p>
                    </li>
                </ol>

                <li>
                    <h4>Registration with Publett</h4>
                </li>
                <ol type="a">
                    <li>
                        <p>You do not register with us, and we do not collect any personally identifiable information from you. Sign in is through iOS or Android and does not require you to obtain a paid subscription to the free account or trial period. </p>
                    </li>
                    <li>
                        <p>Any information we collected under essential circumstances is solely to provide service to you. </p>
                    </li>
                    <li>
                        <p>Apple and Android use personal data to power their services, process your transactions, communicate with you for security and fraud prevention, and comply with the law. They may also use personal data for other purposes with your consent.</p>
                    </li>
                    <li>
                        <p>Your account may only be used by you, and any unauthorised or suspicious activity should be communicated to Publett immediately. Keeping your password safe and confidential is entirely your responsibility.  </p>
                    </li>
                </ol>

                <li>
                    <h4>Executing Paid Subscription Agreement</h4>
                </li>
                <ol type="a">
                    <li>
                        <p>After registering, paid subscriptions are available to you for different periods. Subscription access is ad-free and allows you additional content and features. Currently, we only offer one package Publett Basic that allows ad-free access to all content with a valid subscription.  </p>
                    </li>
                    <li>
                        <p>The fee-based subscription via an in-app purchase. To do this, you must select your preferred option on the subscription screen within the App, and a pop-up will appear where you have to enter your password for the third-party Appstore. As soon as you finish, a pop-up will appear in which you have to confirm the purchase of the subscription again. At this point, you can still cancel the process. You should then receive a receipt confirmation from the third-party Appstore (not directly from Publett). </p>
                    </li>
                    <li>
                        <p>Notice of acceptance (order confirmation) by separate Publett email with T&amp;Cs, and your subscription begins on this date. This is a legally binding offer for a fee-based subscription. A contract is concluded when Publett issues an order confirmation. </p>
                    </li>
                </ol>

                <li>
                    <h4>Trial Phases</h4>
                </li>
                <ol type="a">
                    <li>
                        <p>Publett offers trial phases to new users for testing purposes for a limited time and is at our sole discretion if you participate. If necessary, we may discontinue or change the free trial at any time without prior notice. </p>
                    </li>
                </ol>

                <li>
                    <h4>Cancellation policy</h4>
                </li>
                <ol type="a">
                    <li>
                        <p>If you are a consumer, you have the right to withdraw within the respective app store (Apple-App-Store and Google Play Store). </p>
                    </li>

                    <li>
                        <p>iOS refund policy: https://www.apple.com/legal/internet-services/itunes/ </p>
                        <p>For more details about how Transactions are billed, please visit http://support.apple.com/HT201359. All transactions are final. Content prices may change at any time. If technical problems prevent or unreasonably delay content delivery, your exclusive and sole remedy is either replacement of the content or refund of the price paid, as determined by Apple. From time to time, Apple may suspend or cancel payment or refuse a refund request if we find evidence of fraud, abuse, or unlawful or other manipulative behaviour that entitles Apple to a corresponding counterclaim. Terms related to store credit and gift cards/codes are available here: https://www.apple.com/legal/internet-services/itunes/giftcards/.</p>
                    </li>

                    <li>
                        <p>Google refund policy: https://support.google.com/googleplay/answer/2479637?hl=en-GB</p>
                        <p>Google doesn't refund most Google Play purchases. However, there are exceptions detailed below. You can also contact the developer directly. The developer can help with purchase issues and process refunds according to their policies and applicable laws. While this is permitted in the google store, our Publett policy allows you to contact the developer 72hrs after that contract is made only if you missed your 48hr withdrawal period. However, this may come with charges in the amount in proportion to what has been provided.</p>
                            <ul style={{marginBottom: 20}}>
                                <li>Within 48 hours: You may get a refund from google depending on the details of the purchase. https://support.google.com/googleplay/answer/7205930</li>
                                <li>Within 30mins you may get an immediate refund</li>
                            </ul>
                        
                    </li>
                </ol>
                <li>
                    <h4>Reimbursement and Payment Methods, Compensation and Retention Rights</h4>
                </li>
                <ul>
                    <li>
                        <p>The subscription fee is paid at the beginning of the subscription period</p>
                    </li>

                    <li>
                        <p>Payment is through your respective app store process</p>
                    </li>

                    <li>
                        <p>Subscription is auto-renewed if you do not cancel before, according to your app store policies</p>
                    </li>

                    <li>
                        <p>You will be noticed about price changes via your app store</p>
                    </li>
                </ul>


                <li>
                    <h4>Term and Cancellation</h4>
                </li>

                <ol type="a">
                    <li>
                        <p>Subscription Access cancellation takes effect following the last day of your current subscription period. You will be downgraded to the free account agreement. However, once you renew a subscription again, you will be able to access your old status and reading lists. </p>
                    </li>

                    <li>
                        <p>If you wish to delete your account, you can do so from Publett.com. </p>
                    </li>
                </ol>

                <li>
                    <h4>Gift codes and special offers</h4>
                </li>

                <ol type="a">
                    <li>
                        <p>It can only be used on registered accounts, and these T&amp;Cs also apply. </p>
                    </li>

                    <li>
                        <p>Once the gift or special period expires, you will be downgraded to a free account and can purchase a new subscription at any time you wish. </p>
                    </li>
                    
                    <li>
                        <p>Our monthly and annual introductory offer currently runs from now till 31st December 2022, there after it will return to the full price on 01st Jan 2023. </p>
                    </li>
                </ol>

                <li>
                    <h4>Liability</h4>
                </li>

                <ol type="a">
                    <li>
                        <p>Publett disqualifies any claims for damages arising from you. This does not apply to claims for damages arising from the breach of essential contractual obligations, which are the fulfilment of which enables the proper execution of the agreement. In this event, we shall only be liable for the typical, foreseeable damages stipulated in a contract. </p>
                    </li>
                </ol>

                <li>
                    <h4>Final Provisions</h4>
                </li>

                <ul>
                    <li>
                        <p>Governing Law; Dispute Resolution</p>
                        <p>
                            You agree that all matters relating to your access to or use of the site, including all disputes, will be governed by the laws of England and Wales.
                            If you are a consumer based elsewhere, you may make a claim in the courts of the country where you reside. Any claim under these Terms of Use must be brought within one year after the cause of action arises, or such claim or cause of action is barred.
                            No recovery may be sought or received for damages other than out-of-pocket expenses, except that the prevailing party will be entitled to costs and attorneys' fees. In the event of any controversy or dispute between Apple and you arising out of or in connection with your use of the Site, the parties shall attempt to resolve any such dispute promptly and in good faith. If we cannot resolve any such dispute within a reasonable time (not to exceed thirty (30) days), then either party may submit such controversy or dispute to mediation. If the dispute cannot be resolved through mediation, then the parties shall be free to pursue any right or remedy available to them under applicable law.
                        </p>
                    </li>

                    <li>
                        <p>className ACTION WAIVER
                            WHERE PERMITTED UNDER THE APPLICABLE LAW, YOU AND PUBLETT AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR className MEMBER IN ANY PURPORTED className OR REPRESENTATIVE ACTION. Unless both you and Publett agree, no arbitrator or judge may consolidate more than one person's claims or otherwise preside over any form of a representative or className proceeding.
                        </p>
                    </li>
                </ul>
            </ol>
        </main >
    );
}