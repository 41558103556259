import React from "react";

import './styles.css'

function BlogIntroduction() {
    return (
        <div className="Behindtheapp">
            <div className="container">
                <div className="row Behindtheapp__Introduction">
                    <div className="col-10 offset-1 col-sm-12 offset-sm-0">
                        <div className="Behindtheapp__Title">Behind The App</div>
                        <div className="Behindtheapp__Subtitle">Publett is an app that provides summaries of published scientific articles. The founders recognised the <b><i>painful, time-consuming task of reading</i></b> such articles (both scientists themselves!) and decided to solve this. They did the reading for you but presented it nicely packaged into something called a Publett.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BlogIntroduction;