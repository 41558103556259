import React from "react";

import './styles.css'

function PubcastHeader() {
    return (
        <div className="Pubcastheader__Introduction">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="Pubcastheader__Introduction__Title">Pubcasts</div>
                        <div className="Pubcastheader__Introduction__Subtitle">Unearthing controversies, discoveries and addressing the uncomfortable topics in research.</div>
                    </div>
                    <div>
                        <p className="Pubcastheader__PodcastApps">Available on</p>
                        <a href="https://open.spotify.com/show/5LxCfscMprj4VFgd2qNmi4?si=76643cd7caa94223" target="_blank"><img src={process.env.PUBLIC_URL + '/assets/podcast_spotify.webp'} style={{ width: 30, marginRight: 15 }}></img></a>
                        <a href="https://podcasts.apple.com/us/podcast/the-diary-of-a-researcher-by-publett/id1653300115" target="_blank" title="Listen on Apple Podcasts"><img src={process.env.PUBLIC_URL + '/assets/podcast_apple.webp'} style={{ width: 30, marginRight: 15 }}></img></a>
                        <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnBvZGJlYW4uY29tL3RoZS1kaWFyeS1vZi1hLXJlc2VhcmNoZXIvZmVlZC54bWw" target="_blank"><img src={process.env.PUBLIC_URL + '/assets/podcast_google.webp'} style={{ width: 30, marginRight: 15 }}></img></a>
                        <a href="https://player.fm/series/3414058" target="_blank"><img src={process.env.PUBLIC_URL + '/assets/podcast_player-fm.webp'} style={{ width: 30, marginRight: 15 }}></img></a>
                        <a href="https://music.amazon.com/podcasts/79513bcb-3e3e-41c6-9c87-3fa9a77ee403" target="_blank"><img className="no-selection" src={process.env.PUBLIC_URL + '/assets/podcast_amazon.webp'} style={{ width: 30, marginRight: 15 }}></img></a>
                        <a href="https://r4j68.app.goo.gl/?link=https%3A%2F%2Fthe-diary-of-a-researcher.podbean.com%3Faction%3DopenPodcast%26podcastId%3Dpbblog14834559%26podcastIdTag%3Dmbgmn&dfl=https%3A%2F%2Fthe-diary-of-a-researcher.podbean.com&utm_campaign=pb_web&utm_source=podbean_web&utm_medium=dlink&imv=670&amv=530&isi=973361050&ibi=com.podbean.app.podcast&ipbi=com.podbean.app.podcast&apn=com.podbean.app.podcast" target="_blank"><img className="no-selection" src={process.env.PUBLIC_URL + '/assets/podcast_podbean.webp'} style={{ width: 30 }}></img></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PubcastHeader;