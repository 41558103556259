import React from "react";

import AppBenefitCard from '../AppBenefitCard0/AppBenefitCard';
import AppCarousel from '../AppCarousel/AppCarousel';

import './styles.css';

function PublettApp() {
    return (
        <div className="publettapp">
            {/* <div className="row publettapp__header">
                
                <div className="col-10" style={{textAlign: 'right'}}>
                    <h1 className="publettapp__title">Publett</h1>
                    <h2 className="publettapp__subtitle">Available on iOS, Android and the web</h2>
                    <span className="badge pill bg-info">Search Publett in your app store</span>
                </div>
                <div className="col-2">
                    <img style={{ borderRadius: 5 }} src="assets/publettLogoLilac.webp" width="100%" />
                </div>
            </div> */}

            <div className="row publettapp__header">

                <div className="col-12">
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                        <img style={{ borderRadius: 5, margin: 0, display: 'block', padding: 0, marginRight: 10 }} src="assets/publettLogoLilac.webp" width="60" />
                        <div className="col">
                            <h1 className="publettapp__title">Publett</h1>
                            <p className="publettapp__title--thin">Your new learning companion</p>
                        </div>
                    </div>
                    <h2 className="publettapp__subtitle">Available on iOS and Android</h2>
                    {/* <span className="badge pill bg-info">Search Publett in your app store</span> */}
                </div>
            </div>


            {/* <AppCarousel /> */}
            <p style={{ marginBottom: 20 }}>If you struggle to keep up with your reading, struggle to find time to read or simply think of it as a chore because it's hard, don't worry, we're here to help you:  </p>
            <AppBenefitCard />
            {/* <h2 className="publettapp__subtitle">Do you struggle to find time, relevant publications and the latest research in your field?</h2> */}
            {/* <p>Don't worry you are not alone. According to [xyz] It is reported that 60% of academics and researchers are strugling with the same problems. <span style={{ fontWeight: 600, color: 'blue' }}>Listen to our Publett on the topic.</span> and <span className="badge bg-info">get started on our free-forever plan</span></p> */}
            {/* <p>We created Publett to overcome these and many problems people face. Access publications in easy to consume audio and text formats, summarised by experts with full DOI available for reference or further reading.</p> */}
            {/* <h3 className="publettapp__h3">Features</h3> */}
            <p style={{ marginTop: 20 }}>Now it's easier than ever to fit learning into your routine with these convenient features:</p>
            <AppCarousel />
            {/* <AppBenefitCard /> */}
            {/* <p>We created Publett to overcome these and many problems people face. Access publications in easy to consume audio and text formats, summarised by experts with full DOI available for reference or further reading?</p> */}
            <p style={{ marginTop: 20 }}>And you’re not alone! You don’t have to stay stuck behind. You can get ahead with the growing community of Publetters! </p>
            {/* <p> We call it making science a leisure, and you can listen anywhere, anytime, any device.</p> */}
            {/* <span className="badge bg-primary">Get started with our free-forever plan</span> */}
            {/* <div>
                <img src={process.env.PUBLIC_URL + 'assets/app-store.svg'} style={{height: 30, marginRight: 15}} />
                <img src={process.env.PUBLIC_URL + 'assets/play-store.svg'} style={{height: 30}} />
            </div> */}
            <div>
                <a href="https://apple.co/3A8Hu1e" target="_blank"><img src={process.env.PUBLIC_URL + '/assets/app-store.svg'} style={{ height: 30, marginRight: 15 }} /></a>
                <a href="https://play.google.com/store/apps/details?id=com.publett" target="_blank"><img src={process.env.PUBLIC_URL + '/assets/play-store.svg'} style={{ height: 30 }} /></a>
            </div>
        </div>
    )
}

export default PublettApp;