import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import './styles.css';

import { Link, useParams } from "react-router-dom";

import PubcastHeader from "../../components/PubcastHeader/PubcastHeader";


function PubcastDetailPage() {
    // const [documentWidth, setDocumentWidth] = React.useState(document.body.clientWidth);
    // const breakpoint = 992;

    let { uuid } = useParams();
    const [episode, setEpisode] = useState(null);
    const [episodeResult, setEpisodeResult] = useState(null);
    // const shareUrl = 'https://www.publett.com/blog/' + uuid;

    // let author, authorBio, authorImage, authorRole, content, date, duration, image, title = 'none';
    // image = 'iphone2.jpeg';

    // let blogPost = BlogData.find((i) => i.id === uuid);
    // if (blogPost) {
    //     author = blogPost.author;
    //     authorBio = blogPost.authorBio;
    //     authorImage = blogPost.authorImage;
    //     authorRole = blogPost.authorRole;
    //     content = blogPost.content;
    //     date = blogPost.date;
    //     duration = blogPost.duration;
    //     image = blogPost.image;
    //     title = blogPost.title;
    // }
    // let location = process.env.PUBLIC_URL + 'assets/' + image;
    // let headerImageURL = `url('../../assets/blogs/${image}')`;

    // const MoreBlogPosts = () => {
    //     // copy original dataset
    //     let tempData = JSON.parse(JSON.stringify(BlogData));

    //     //find index of current post
    //     let pos = tempData.findIndex((i) => i.uuid === uuid);

    //     //remove current post from dataset
    //     tempData.splice(pos, 1);

    //     return (
    //         tempData.slice(0, 4).map((item) => (
    //             <div key={item.uuid} className="col">
    //                 <Link style={{ textDecoration: 'none', color: '#333333' }} to={`/blog/${item.uuid}`}>
    //                     <div className="row blogpost__share__body g-2">
    //                         <div className="col-2 col-md-3 col-lg-2 col-xl-3 ">
    //                             <div className="ratio ratio-1x1 blogpost__thumbnail" style={{ '--me': `url('../../assets/blogs/${item.image}'` }} />
    //                         </div>
    //                         <div className="col-10 col-md-9 col-lg-10 col-xl-9 Blogpost__More__Post__Title">{item.title}</div>
    //                     </div>
    //                 </Link>
    //             </div>
    //         )
    //         )
    //     )
    // }

    useEffect(() => {
        async function postData(url = '', data = {}) {
            // Default options are marked with *
            // console.log(url, data);
            const response = await fetch(url, {
                method: 'GET',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache',
                credentials: 'omit', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    //'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                // body: JSON.stringify(data)
            });
            // console.log(response.json())
            return response.json();
        }

        if (uuid) {
            // console.log(uuid);
            postData('https://www.publett.com/api/pubcast.php?uuid=' + uuid, { uuid: uuid })
                .then((data) => {
                    const { result = 'error', episode = '', message = '' } = data;

                    if (result === 'ok') {
                        setEpisodeResult(result);
                        setEpisode(episode);
                    }

                    if (data.result !== 'ok') {
                        setEpisodeResult(result);
                    }

                    // console.log(episode);
                });
        }
    }, [uuid]);

    return (
        <>
            <Helmet>
                {/* <title>Publett | Blog | {title}</title>
                <meta name="description" content="Publett - Science made a leisure" /> */}

                {/* facebook meta tags */}
                {/* <meta property="og:title" content={title} />
                <meta property="og:type" content="article" />
                <meta property="og:image" content={`https://www.publett.com/assets/blogs/${image}`} />
                <meta property="og:image:url" content={`https://www.publett.com/assets/blogs/${image}`} />
                <meta property="og:image:secure_url" content={`https://www.publett.com/assets/blogs/${image}`} />
                <meta property="og:description" content="Read at publett.com" />
                <meta property="og:url" content={shareUrl} />
                <meta property="og:site_name" content="Publett" />
                <meta property="og:image:alt" content={title} /> */}

                {/* twitter meta tags */}
                {/* <meta name="twitter:card" content="summary" />
                <meta name="twitter:text:title" content={title} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content="Read at publett.com" />
                <meta name="twitter:site" content="Publett" />
                <meta name="twitter:image" content={`https://www.publett.com/assets/blogs/${image}`} />
                <meta name="twitter:site" content="@publett" />
                <meta name="twitter:creator" content="@publett" /> */}
            </Helmet>
            <div className="Pubcast__Header">
                <div className="container">
                    <PubcastHeader />
                </div>
            </div>
            <div className="container">
                <div className="Pubcastdetail__Return__Block">
                    <Link className="Pubcastdetail__Return" to="/pubcast">
                        <img className="Pubcastdetail__Return__Icon" src={process.env.PUBLIC_URL + '/assets/return.svg'} />
                        <p className="Pubcastdetail__Return__Text">Pubcasts</p>
                    </Link>
                </div>
                {!episodeResult && (<div className="spinner-border m-5" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>)}

                {episodeResult === 'error' && (
                    <div className="Pubcastdetail__Block">
                        <div className="Pubcastdetail__Title">Sorry we couldn't find that episode</div>
                    </div>
                )}

                <div className="Pubcastdetail__Block">
                    {episode?.title && (<div className="Pubcastdetail__Title">{episode.title}</div>)}
                    {episode?.topic && (<div className="Pubcastdetail__Subtitle">{episode.topic}</div>)}
                    {episode?.host && episode?.hostee && (<div className="Pubcastdetail__Hosts">Hosts: {episode.host} and {episode.hostee}</div>)}
                    {episode?.summary && (<div className="Pubcastdetail__Summary">{episode.summary}</div>)}
                </div>
                {episode?.topics && (
                    <div className="Pubcastdetail__Block">
                        <div className="Pubcastdetail__Subtitle">Topics</div>
                        <ul>
                            {
                                episode.topics.map((item, id) => (
                                    <li key={`topic_${id}`} className="Pubcastdetail__Text">{item.topic}</li>
                                ))
                            }
                        </ul>
                    </div>
                )}

                {episode?.resources && (
                    <div className="Pubcastdetail__Block">
                        <div className="Pubcastdetail__Subtitle">Resources</div>
                        <ul>
                            {
                                episode.resources.map((item, id) => (
                                    <li key={`resource_${id}`} className="Pubcastdetail__Text">
                                        <a href={item.link} target="_blank" rel="noreferrer">{item.link_display}</a>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>

                )}


            </div>

        </>
    )
}
export default PubcastDetailPage;