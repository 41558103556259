import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom';

import PubcastHeader from "../../components/PubcastHeader/PubcastHeader";

import './styles.css';
import { useEffect } from "react";

function PubcastPage() {

    const { parse } = require('rss-to-json');
    const [pubcasts, setPubcasts] = useState(null);

    useEffect(() => {
        parse('https://feed.podbean.com/the-diary-of-a-researcher/feed.xml').then(rss => {
            setPubcasts(rss);
        });
    }, [parse]);

    const PubcastDetail = props => {
        const { itunes_summary, itunes_image, link, title } = props;
        //console.log(props);
        const linkLen = link.length - 1;
        const linkSplit = link.indexOf('_') + 1;

        const shortSummary = itunes_summary.substring(0, itunes_summary.indexOf('Episode resources:'));
        

        const uuid = link.substring(linkSplit, linkLen);
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: 10}}>
                    {/* <img src={itunes_image.href} style={{ width: 75, minWidth: 75, height: 75, marginRight: 15, borderRadius: 5}} /> */}
                    <div>
                        <div style={{ fontWeight: 500, fontSize: 20 }}>{title}</div>
                        <div style={{ fontWeight: 300, marginTop: 10, color: '#444444' }}>{shortSummary}</div>
                        
                        <div className="Pubcast__Resourceslink__Block">
                        <Link className="Pubcast__Resourceslink" to={`/pubcast/${uuid}`}>
                            <p className="Pubcast__Resourceslink__Text">Episode Resources</p>
                            <img className="Pubcast__Resourceslink__Icon" src={process.env.PUBLIC_URL + '/assets/forward.svg'} />
                        </Link>
                        </div>
                </div>
            </div>
            </>
        )
}

return (
    <>
        <Helmet>
            <title>Publett | Pubcast</title>
            <meta name="description" content="Publett - Science made a leisure" />
        </Helmet>
        <div className="Pubcast__Header">
            <div className="container">
                <PubcastHeader />
            </div>
        </div>
        <div className="container">
            <div className="row Blog__Section">
                <div className="Blog__Section__Title">Episodes</div>
                {
                    pubcasts?.items?.length > 0 && (
                        pubcasts.items.map((item, id) => (
                            <PubcastDetail key={id} {...item} />
                        ))
                    )
                }
            </div>
        </div>
    </>
)
}
export default PubcastPage;