export default function Privacy() {
  return (
    <main style={{ padding: 30 }}>
      <h3 style={{ marginBottom: 30 }}>Publett Privacy Policy</h3>
      <ol>
        <li>
          <h4>Your Rights</h4>
        </li>
        <ol type="a">
          <li>
            <p>You have the right to handle your data at any time, consent can be revoked, and you may partially object to its processing. Any other issues, contact us at <a mailto="privacy@publett.com">privacy@publett.com</a></p>
          </li>
        </ol>

        <li>
          <h4>Data we collect</h4>
        </li>
        <ol type="a">
          <li>
            <p>Communicated data: data you give us through the contact forms.</p>
          </li>
          <li>
            <p>Automatic data: when visiting the website, technical data such as time of visit.</p>
          </li>
          <li>
            <p>Registration: subscriptions are through the app and play store, so we only receive a token ID from your provider based on your login type. Sometimes it is pseudonymised, so check with your relevant store.</p>
          </li>
          <li>
            <p>Cookies: we do not collect any at the moment.</p>
          </li>
          <li>
            <p>Tracking: we do't have time for that at the moment.</p>
          </li>
          <li>
            <p>Social plugins: Clicking on one of our social buttons can send data like your IP address and browser/device info.</p>
          </li>
          <li>
            <p>Newsletter: when you voluntarily sign up, your info will be used for promotional purposes, and you can unsubscribe at any time.</p>
          </li>
          <li>
            <p>Payment: We do not handle any payment information, and this is done through your respective app store.</p>
          </li>
          <li>
            <p>Children: we do not collect data from children, do not send such information to us; we will delete it immediately. If you suspect that this is the case, contact us at privacy@publett.com.</p>
          </li>
        </ol>


        <li><h4>What we use it for </h4></li>
        <ol type="a">
          <li>
            <p>To offer you an optimised service in addition to advertising and marketing research.</p>
          </li>
        </ol>

        <li><h4>Partners</h4></li>
        <ol type="a">
          <li>
            <p>Currently, we are not using data in external collaborations.</p>
          </li>
        </ol>

        <li><h4>Use of your data for other purposes</h4></li>
        <ol type="a">
          <li>
            <p>Trouble-shooting.</p>
          </li>
        </ol>
      </ol>
    </main>
  );
}