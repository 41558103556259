import React from "react";

import { Outlet} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import ContactForm from './components/ContactForm/ContactForm';
import Footer from './components/Footer/footer';
import Navigation from './components/Navigation/Navigation';

import './App.css';

function App() {
  const helmetContext = {};
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Navigation />
        <Outlet />
        <Footer />
        <ContactForm />
      </HelmetProvider>
    </>
  );
}

export default App;
