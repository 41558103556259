import Carousel from 'react-bootstrap/Carousel'

//import Design1 from '../assets/Design1.png'

import './styles.css';

export default function WelcomeCarousel() {
    return (
        <div style={{borderRadius: 5, overflow: 'hidden'}}>
            <Carousel variant="dark">
            <Carousel.Item className="welcomecarousel__carouselitem welcomecarousel__carouselitem--pink">
                {/* <img src={Design1} /> */}
                <div className="welcomecarousel__carouselitem__div">
                    <p className="welcomecarousel__carouselitem__div__p"><span className="welcomecarousel__carouselitem__div__p__span--bold">Science articles summarised</span> into bite-sized listens and reads, so you don't fall behind on your reading.</p>
                </div>

                <Carousel.Caption>
                    <h4>Summarised</h4>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item className="welcomecarousel__carouselitem welcomecarousel__carouselitem--blue">
                {/* <img src={Design1} /> */}
                <div className="welcomecarousel__carouselitem__div">
                    <p className="welcomecarousel__carouselitem__div__p">We try not change the meaning at all, so if you want to<span className="welcomecarousel__carouselitem__div__p__span--bold"> reference</span> something, go ahead, it's all there!</p>
                </div>

                <Carousel.Caption>
                    <h4>Reliable</h4>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item className="welcomecarousel__carouselitem  welcomecarousel__carouselitem--green">
                {/* <img src={Design1} /> */}
                <div className="welcomecarousel__carouselitem__div">
                    <p className="welcomecarousel__carouselitem__div__p">Left you craving more info? Just click on the DOI link to the<span className="welcomecarousel__carouselitem__div__p__span--bold"> full text open-source article.</span></p>
                </div>

                <Carousel.Caption>
                    <h4>Open-Source</h4>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item className="welcomecarousel__carouselitem  welcomecarousel__carouselitem--purple">
                {/* <img src={Design1} /> */}
                <div className="welcomecarousel__carouselitem__div">
                    <p className="welcomecarousel__carouselitem__div__p">Something caught your eye but haven't the time? Quickly<span className="welcomecarousel__carouselitem__div__p__span--bold"> save for later!</span></p>
                </div>

                <Carousel.Caption>
                    <h4>Save and review</h4>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item className="welcomecarousel__carouselitem welcomecarousel__carouselitem--peach">
                {/* <img src={Design1} /> */}
                <div className="welcomecarousel__carouselitem__div">
                    <p className="welcomecarousel__carouselitem__div__p">Create a<span className="welcomecarousel__carouselitem__div__p__span--bold"> play queue</span> for your commute to work, an interview, in lab or during workouts</p>
                </div>

                <Carousel.Caption>
                    <h4>Multitask</h4>
                </Carousel.Caption>
            </Carousel.Item>

            {/* <Carousel.Item className="welcomecarousel__carouselitem">
                <div className="welcomecarousel__carouselitem__div">
                    <p className="welcomecarousel__carouselitem__div__p">Going to be offline for a while?<span className="welcomecarousel__carouselitem__div__p__span--bold"> Download items</span> in your queues ready for playback at your convenience.</p>
                </div>

                <Carousel.Caption>
                    <h4>Offline mode</h4>
                </Carousel.Caption>
            </Carousel.Item> */}
        </Carousel>
        </div>
    );
}